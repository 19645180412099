import axios from '@/libs/axios';

const baseUrl = '/scribe-summaries'

export default {
  update(id, rating) {
    return axios.put(`${baseUrl}/${id}`, {
      rating: rating,
    })
  }
}
